import React from "react";
import styles from "../PricingCard.module.css";
import ProTag from "@/mvcComponents/Commons/ProTag/ProTag.controller";
import AnalyticsManager from "@/outscal-commons-frontend/Managers/Analytics/AnalyticsManager";
import { UIElements } from "@/outscal-commons-frontend/Hooks/useAnalyticsClickEvent";
import DynamicIcon from "@/mvcComponents/Commons/DynamicIcons/DynamicIcons.controller";

const CourseStatusPricingButtonView = ({
  isProUser,
  userCourseStatus,
  analyticsId,
  paymentType,
  price,
  hasCourseProAccess,
}) => {
  const isCourseFree = price == 0;

  const paymentNotNeeded =
    isProUser && hasCourseProAccess && paymentType == "CONTENT";

  return userCourseStatus?.text == "Enrolled" ? (
    <span className={styles.ctaText}>
      <DynamicIcon icon="FiCheckCircle" size={14} />
      {userCourseStatus?.text}
    </span>
  ) : (
    <div
      className={`${
        styles[isCourseFree || paymentNotNeeded ? "cta" : "yellow-cta"]
      }`}
      onClick={(e) => {
        AnalyticsManager.clickEvent(e);
        userCourseStatus.action();
      }}
      data-analytics={[UIElements.BUTTON, analyticsId]}
    >
      {(isCourseFree || paymentNotNeeded) && (
        <ProTag fontSize="18px" padding="4px 0 0" />
      )}{" "}
      {userCourseStatus?.text}
    </div>
  );
};

export default CourseStatusPricingButtonView;
