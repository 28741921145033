import React from "react";
import Link from "next/link";
import { UIElements } from "@/outscal-commons-frontend/Hooks/useAnalyticsClickEvent";
import styles from "./ContactCareerAdvisor.module.css";

const ContactCareerAdvisorView = ({
  analyticsName,
  heading,
  link,
  subheading,
  action,
}) => {
  return (
    <div
      id={analyticsName}
      className={styles.root}
      onClick={() => {
        if (action) {
          action?.action();
        }
      }}
      data-analytics={[UIElements.CARD, analyticsName]}
      rel="nofollow"
    >
      <div className={styles.detailsWrap}>
        <h2 className={styles.heading}>{heading}</h2>
        {subheading && <p className={styles.subheading}>{subheading}</p>}
      </div>
      {link ? (
        <Link
          href={link.link}
          data-analytics={[UIElements.LINK, `${analyticsName}-link`]}
          rel="nofollow"
        >
          <button
            className={styles.cta}
            hasCourseLink={link.link.includes("/course/")}
            rel="nofollow"
          >
            {link.text}
          </button>
        </Link>
      ) : (
        <button
          className={styles.cta}
          data-analytics={[UIElements.BUTTON, `${analyticsName}-cta`]}
        >
          {action?.text}
        </button>
      )}
    </div>
  );
};

export default ContactCareerAdvisorView;
