import React from "react";
import { FaCrown } from "react-icons/fa";
import styles from "./ProTag.module.css";

const ProTagView = ({ fontSize, padding, bgColor }) => {
  return (
    <FaCrown
      className={styles["pro-tag"]}
      style={{
        "--border-radius": "0",
        "--font-weight": "600",
        "--font-size": fontSize,
        "--padding": padding,
        "--primary-color": bgColor,
        "--outline-color": "#000000",
      }}
    />
  );
};

export default ProTagView;
