export const defaultData = [
  {
    heading: "",
    subHeading: "",
    listData: [
      {
        heading: "",
        subHeading: "",
        currency: "",
        price: 0,
        tagText: "popular",
        ctaText: "",
        ctaLink: "",
        features: {
          0: {
            text: "",
            type: "",
            icon: "FaCheck",
          },
        },
      },
    ],
  },
];
