import React from "react";
import PricingCard from "../../Cards/PricingCard/PricingCard.controller";
import GridLayout from "@/mvcComponents/Commons/GridLayout/GridLayout.controller";
import styles from "./PricingA.module.css";
import ContactCareerAdvisor from "@/mvcComponents/Commons/ContactCareerAdvisor/ContactCareerAdvisor.controller";
import ClientAppManager from "@/Logic/ClientLogic/Services/ClientAppManager";

const PricingAView = ({
  id,
  heading,
  subHeading,
  listData,
  analyticsId,
  additionalText,
  contactAdvisorPlugData,
  amountData,
  isLoggedIn,
  permissions,
  showCallAdvisor,
  showCards,
}) => {
  let cards = listData.filter((item) => {
    let result = null;
    if (showCards === "ALL" || showCards === null) {
      result = item;
    } else if (showCards === "COUNTRY") {
      if (
        item.showType === "OTHER" &&
        ClientAppManager.instance.timeZone !== "Asia/Calcutta"
      ) {
        result = item;
      } else if (item.showType === ClientAppManager.instance.timeZone) {
        result = item;
      }
    }
    return result;
  });
  return (
    <section
      id={id}
      data-outscal-section
      className={`pricing-section ${styles.pricingGrid}`}
    >
      <div>
        <GridLayout
          col={cards?.length < 3 ? cards.length : 2}
          heading={heading}
          subHeading={subHeading}
          name={analyticsId}
          gridGap={24}
          justifyContent="space-evenly"
          alignItems="none"
          cardComponent={PricingCard}
          customCardData={{
            amountData,
            isLoggedIn,
            permissions,
          }}
          listData={cards}
          minCardWidth={"400px"}
        />
        <p className={styles.text}>{additionalText}</p>
      </div>

      {showCallAdvisor && <ContactCareerAdvisor {...contactAdvisorPlugData} />}
    </section>
  );
};

export default PricingAView;
