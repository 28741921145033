import React from "react";
import styles from "./PricingCard.module.css";
import layoutStyles from "@/styles/LayoutStyles.module.css";
import DynamicIcon from "@/mvcComponents/Commons/DynamicIcons/DynamicIcons.controller";

const FeaturesList = ({ features }) => {
  return (
    <div
      className={`${layoutStyles["col-div"]}`}
      style={{
        gap: "6px",
      }}
    >
      {features?.map((feature, index) => {
        const {
          text = "",
          icon = "BsCheck2Circle",
          included = true,
        } = feature || {};
        return (
          <div
            key={index}
            className={`${layoutStyles["row-div"]}`}
            style={{
              gap: "6px",
              "--align-items": "center",
            }}
          >
            <DynamicIcon
              icon={icon}
              size={"14px"}
              color={included ? "#3f57cb" : "#ff3b30"}
            />

            <p
              className={`${
                included ? styles.featureIncluded : styles.feature
              }`}
            >
              {text}
            </p>
          </div>
        );
      })}
    </div>
  );
};

export default FeaturesList;
