import React from "react";
import styles from "./PricingCard.module.css";
import useUserActions from "@/store/actions/UserProfileActions/userAction";
import UserAccessTypeEnum from "@/outscal-commons-frontend/Enums/userAccessTypeEnum";
import UserCourseService from "@/Logic/ClientLogic/UserCourseService/UserCourse.service";
import { useRouter } from "next/router";

const PriceDetails = ({
  currency,
  price,
  priceMeta,
  paymentType,
  hasCourseProAccess,
  amount,
  pricingSubText,
  amountSubscriptText,
}) => {
  const router = useRouter();
  const { user } = useUserActions();
  const isProUser =
    user?.userDetails?.user_access?.type ==
    UserAccessTypeEnum.getValue("pro-trial-user");
  const isCourseFree = price == 0;
  const paymentNotNeeded =
    isProUser && hasCourseProAccess && paymentType == "CONTENT";

  return (
    <div className={`${styles.pricing}`}>
      <div className={styles["pricing-main"]}>
        {!isCourseFree && paymentNotNeeded && (
          <span
            className={`${styles.currency} ${
              !isCourseFree && paymentNotNeeded ? styles.strikethrough : ""
            }`}
          >
            {currency && !(isCourseFree || paymentNotNeeded) ? currency : ""}
            {amount > 0 ? amount : ` ${price > 0 ? price : ""}`}
          </span>
        )}

        <p className={`${styles.price}`}>
          {currency ? currency : ""}
          {amount > 0 ? amount : ` ${price > 0 ? price : ""}`}
          {UserCourseService.instance?.courseFromStrapi?.courseType == "FREE" &&
          router.pathname == "/course/[courseSlug]"
            ? "FREE"
            : ""}
        </p>

        {amountSubscriptText && (
          <span className={styles["price-meta"]}>{amountSubscriptText}</span>
        )}

        {price > 0 &&
          priceMeta &&
          !paymentNotNeeded &&
          !amountSubscriptText && (
            <span className={`${styles["price-meta"]}`}>{priceMeta}</span>
          )}
      </div>

      {pricingSubText && (
        <span className={styles["pricing-sub-text"]}>{pricingSubText}</span>
      )}
    </div>
  );
};

export default PriceDetails;
