
const useScroll = () => {

    const scrollToSectionById = (id) => {
        const targetElement = document.getElementById(id);
        if (targetElement) {
            window.scrollTo({
                top: targetElement.offsetTop - 150,
                behavior: "smooth",
            });
        }
    }

    return { scrollToSectionById }
}

export default useScroll