import React from "react";
import PricingCardView from "./PricingCard.view";
import UserCourseService from "@/Logic/ClientLogic/UserCourseService/UserCourse.service";
import useUserCourseStatusAction from "@/store/actions/userCourseStatusAction";
import { UserCoursePermissionsEnum } from "@/outscal-commons-frontend/Enums";
import useUserActions from "@/store/actions/UserProfileActions/userAction";
import ClientAppManager from "@/Logic/ClientLogic/Services/ClientAppManager";

const PricingCard = ({ name = "", item = {}, customCardData = {} }) => {
  const {
    heading = "",
    subHeading = "",
    features = {},
    tagText = "",
    paymentType = "BASIC",
    beforePurchaseText,
    afterPurchaseText,
    beforePurchaseProText,
    amount = 0,
    amountSubscriptText,
    pricingSubText,
    link = "",
    currency = "$",
    priceMeta = "USD",
    courseType,
  } = item || {};
  const {
    isLoggedIn = false,
    permissions = [],
    amountData = {},
  } = customCardData || {};

  const { user } = useUserActions();
  const { userCourseStatus } = useUserCourseStatusAction();

  const onLinkCardClick = () => {};

  const price =
    UserCourseService.instance?.userCourseController?.getCoursePermissionPrice({
      paymentType,
      amountData,
      permissions,
      isLoggedIn,
    });

  const subscriptionPlan = amountData[paymentType]?.subscriptionPlan;

  return (
    <PricingCardView
      onLinkCardClick={onLinkCardClick}
      paymentType={paymentType}
      analyticsId={name}
      heading={heading}
      subHeading={subHeading}
      permissions={permissions}
      features={Object.values(features)}
      currency={currency}
      price={price}
      priceMeta={priceMeta}
      tagText={tagText}
      showEmiOption={
        subscriptionPlan &&
        !userCourseStatus?.coursePermissions?.includes(
          UserCoursePermissionsEnum.getValue("CONTENT")
        ) &&
        courseType != "PAID-PROJECT"
          ? true
          : false
      }
      emiPlanText={
        subscriptionPlan
          ? user?.userJobProfile?.billing_country_name == "IN"
            ? subscriptionPlan["india"].text
            : subscriptionPlan["outside-india"].text
          : ""
      }
      beforePurchaseText={beforePurchaseText}
      afterPurchaseText={afterPurchaseText}
      beforePurchaseProText={beforePurchaseProText}
      hasCourseProAccess={
        UserCourseService.instance?.courseFromStrapi?.subscriptionAccess ==
        "PRO"
      }
      amount={amount}
      amountSubscriptText={amountSubscriptText}
      pricingSubText={pricingSubText}
      link={link}
      courseType={courseType}
    />
  );
};

export default PricingCard;
