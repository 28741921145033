import React from "react";
import ProTagView from "./ProTag.view";
import { stylesData } from "./ProTag.model";

const ProTag = ({
  fontSize = stylesData.fontSize,
  padding = stylesData.padding,
  bgColor = "#ffcc00",
}) => {
  return <ProTagView fontSize={fontSize} padding={padding} bgColor={bgColor} />;
};

export default ProTag;
