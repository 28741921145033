import React from "react";
import ContactCareerAdvisorView from "./ContactCareerAdvisor.view";
import Link from "next/link";

const ContactCareerAdvisor = ({
  analyticsName,
  heading,
  link,
  subheading,
  action,
}) => {
  return (
    <>
      {link ? (
        <Link href={link.link} id={analyticsName} rel="nofollow">
          <ContactCareerAdvisorView
            analyticsName={analyticsName}
            heading={heading}
            plugImage={plugImage}
            link={link}
            subheading={subheading}
          />
        </Link>
      ) : (
        <ContactCareerAdvisorView
          analyticsName={analyticsName}
          heading={heading}
          subheading={subheading}
          action={action}
        />
      )}
    </>
  );
};

export default ContactCareerAdvisor;
